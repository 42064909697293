.beats-container {
  width: min(100vw, 2100px);
}

.for-you-text {
  padding-left: min(30vw, 20px);
  align-self: flex-start;
  font-size: clamp(20px, 2vw, 30px);
  position: fixed;
  margin-left: 2vw;
  margin-top: clamp(70px, 9vh, 150px);
  margin-top: clamp(70px, 9vh, 150px);
}

.exit-search-btn {
  position: fixed;
  background: transparent;
  border: none !important;
  margin: 2rem;
  margin-top: 4rem;
  font-size: 1.5rem;
}

/* tablet css */

@media (min-width: 480px) and (max-width: 1024px) {
  .for-you-text {
    position: relative;
    margin-top: 5rem;
    font-size: 2rem;
  }
}

/* mobile css */

@media (max-width: 480px) {
  .for-you-text {
    margin-top: 2vh;
    position: relative;
  }
}
