.heading {
  padding-left: min(30vw, 20px);
  align-self: flex-start;
  font-size: clamp(20px, 2vw, 30px);
  z-index: 1;
  margin-top: clamp(70px, 7vh, 150px);
}
/* tablet css */

@media (min-width: 480px) and (max-width: 1024px) {
  .heading {
    margin-top: 5rem;
    font-size: 2rem;
  }
}

/* mobile css */

@media (max-width: 480px) {
  .heading {
    margin-top: 2vh;
  }
}
