@media (min-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Header {
    margin-bottom: 1vh;
  }

  .subHeader {
    font-size: 2vh;
    font-style: italic;
    font-weight: normal;
  }

  .button {
    font-size: 1.25vw;
    font-weight: bold;
    font-style: italic;
    width: 12vw;
    height: 8vh;
    max-height: 75px;
    border-radius: 12px;
    background-color: black;
    border: none;
    color: white;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
  }

  .logo {
    margin-top: 25vh;
    margin-left: 2.5vh;
    width: 10vw;
    height: auto;
  }
}

/* Mobile & Tablet CSS */

@media (max-width: 1024px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin: 15vh 5vw;
  }

  .Header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
  }

  .subHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5vh;
    font-style: italic;
    font-weight: normal;
  }

  .button {
    font-size: 3vw;
    font-weight: bold;
    font-style: italic;
    width: 32vw;
    height: 6vh;
    border-radius: 12px;
    background-color: black;
    border: none;
    color: white;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
  }

  .logo {
    margin-top: 7.5vh;
    margin-left: 2.5vw;
    width: 25vw;
    height: auto;
  }
}
