@import url('https://fonts.cdnfonts.com/css/norwester');

.about {
  font-family: 'Norwester', sans-serif;
}

.typography {
  margin-top: 15vh;
  width: 50vw;
  text-align: start;
  z-index: 1;
}

.logo {
  position: absolute;
  width: 30vw;
  opacity: 0.5;
  z-index: 0;
}

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2rem;
}
