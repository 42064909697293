.title {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: italic;
}

.info-text {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.like-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  font-size: 12px;
}

@media (min-width: 2000px) {
  .row-container {
    width: min(40vw, 1250px);
    text-align: center;
    align-items: center;
  }

  .download-button {
    font-size: 1.5vw;
    margin-right: 1vw;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .title {
    cursor: pointer;
    margin-left: 3vw;
    align-self: flex-start;
    font-size: clamp(15px, 1vw, 20px);
  }

  .info-text {
    margin-left: 3vw;
    margin-top: -1vh;
    align-self: flex-start;
    font-size: clamp(13px, 0.8vw, 16px);
  }

  a {
    font-size: clamp(13px, 0.8vw, 16px);
  }

  .artwork {
    box-shadow: 6px 3px black;
  }
}

@media (min-width: 1920px) and (max-width: 1999px) {
  .row-container {
    width: 55vw;
    max-width: 1200px;
    text-align: start;
    align-items: center;
    align-self: center;
    justify-content: center;
    margin: 1vw;
  }

  .download-button {
    font-size: 1.5vw;
    margin-right: 1vw;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 825px;
  }

  .title {
    cursor: pointer;
    margin-left: 3vw;
    align-self: flex-start;
    font-size: clamp(15px, 1vw, 20px);
    width: clamp(200px, 70%, 500px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .info-text {
    margin-left: 3vw;
    margin-top: -1vh;
    align-self: flex-start;
    font-size: clamp(13px, 0.8vw, 18px);
  }

  a {
    font-size: clamp(13px, 0.8vw, 18px);
  }

  .artwork {
    box-shadow: 6px 3px black;
  }
}

/* this is super fucked but it works */

@media (min-width: 1024px) and (max-width: 1919px) {
  .row-container {
    width: clamp(700px, 60vw, 900px);
    text-align: start;
    align-items: center;
    align-self: center;
    padding: 0 2rem 0 4rem;
    justify-content: center;
    margin: 1vw;
  }

  .download-button {
    font-size: 1.5vw;
    margin-right: 1vw;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2vw;
    max-width: 400px;
  }

  .title {
    cursor: pointer;
    margin-left: 3vw;
    align-self: flex-start;
    font-size: clamp(15px, 1vw, 20px);
    width: clamp(200px, 70%, 500px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .info-text {
    margin-left: 3vw;
    margin-top: -1vh;
    align-self: flex-start;
    font-size: clamp(13px, 0.8vw, 18px);
  }

  a {
    font-size: clamp(13px, 0.8vw, 18px);
  }

  .artwork {
    box-shadow: 6px 3px black;
  }
}

/* tablet css */

@media (min-width: 480px) and (max-width: 1024px) {
  .row-container {
    padding: 1vh;
    width: 75vw;
    justify-content: space-between;
  }

  .download-button {
    display: none;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2vw;
  }

  .title {
    cursor: pointer;
    margin-left: 3vw;
    text-align: start;
    padding: 1vh 0;
    max-width: 30vw;
    font-size: clamp(15px, 1vw, 20px);
  }

  .info-text {
    margin-left: 3vw;
    margin-top: -1vh;
    align-self: flex-start;
    font-size: clamp(13px, 0.8vw, 18px);
  }

  a {
    font-size: clamp(13px, 0.8vw, 18px);
  }

  .artwork {
    box-shadow: 6px 3px black;
  }

  .mobileLikes {
    font-weight: bold;
    padding-top: 2rem;
  }
}

/* mobile css */

@media (max-width: 480px) {
  .row-container {
    padding: 1vh;
    width: 100vw;
    justify-content: space-between;
  }

  .download-button {
    font-size: 1.5vw;
    margin-right: 1vw;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2vw;
  }

  .title {
    cursor: pointer;
    margin-left: 5vw;
    align-self: flex-start;
    font-size: clamp(15px, 1vw, 20px);
    max-width: 50vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .info-text {
    margin-left: 5vw;
    margin-top: -1vh;
    align-self: flex-start;
    font-size: clamp(13px, 0.8vw, 18px);
  }

  a {
    font-size: clamp(13px, 0.8vw, 18px);
  }

  .artwork {
    box-shadow: 6px 3px black;
  }

  .mobileLikes {
    font-weight: bold;
    padding-top: 5vh;
  }
}
/* css for audio playing artwork mask animation */
.playing {
  background: rgba(255, 255, 255, 0.1);
  width: 2rem;
  height: 2rem;
  border-radius: 0.3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.5rem;
  box-sizing: border-box;
}

.playing__bar {
  display: inline-block;
  background: white;
  width: 30%;
  height: 100%;
  animation: up-and-down 1.3s ease infinite alternate;
}

.playing__bar1 {
  height: 60%;
}

.playing__bar2 {
  height: 30%;
  animation-delay: -2.2s;
}

.playing__bar3 {
  height: 75%;
  animation-delay: -3.7s;
}

@keyframes up-and-down {
  10% {
    height: 30%;
  }

  30% {
    height: 100%;
  }

  60% {
    height: 50%;
  }

  80% {
    height: 75%;
  }

  100% {
    height: 60%;
  }
}

.image-container {
  position: relative;
  display: inline-block; /* Or another display type that fits your layout */
}

.playing-animation {
  position: absolute;
  top: 25%;
  left: 25%;
  right: 0;
  bottom: 0;
  background: transparent; /* Semi-transparent overlay */
  color: rgba(255, 255, 255);
  opacity: 0.75;
  height: 50%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

body {
  background: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: sans-serif;
  color: white;
}
