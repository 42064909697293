.search-results-text {
  padding-left: min(30vw, 20px);
  align-self: flex-start;
  font-size: clamp(20px, 2vw, 30px);
  position: fixed;
  margin-left: 2vw;
  margin-top: clamp(70px, 9vh, 150px);
  margin-top: clamp(70px, 9vh, 150px);
}

.container {
  width: 100vw;
}

.beats-container {
  width: 100vw;
  padding-top: 15vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.beats-container > * {
  margin: 2vh 0;
  padding: 0 1vh;
}

.scroll-div {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
}

/* tablet css */

@media (min-width: 480px) and (max-width: 1024px) {
  .search-results-text {
    position: relative;
    margin-top: 5rem;
    font-size: 2rem;
  }

  .beats-container {
    padding-top: 2vh;
    padding-bottom: 10vh;
    height: 80vh;
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }
}

/* mobile css */

@media (max-width: 480px) {
  .container {
    width: 100%;
    position: relative;
    overflow-x: hidden;
  }

  .search-results-text {
    margin-top: 2vh;
    position: relative;
  }

  .beats-container {
    padding-top: 2vh;
    padding-bottom: 10vh;
    height: 80vh;
    overflow-x: hidden;
    position: relative;
    width: 100%;
  }

  .beats-container > * {
    margin: 1vh;
    padding: 1vh;
  }
}
