@media (max-width: 480px) {
  /*

header {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	height: 7.5vh;
}

*/

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40%;
  }

  .orange {
    padding-top: 5vh;
    width: 25vw;
    height: auto;
  }
}
