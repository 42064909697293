.btn {
  border: solid 1px black;
  border-radius: 8px;
  height: clamp(20px, 5vh, 36px);
  margin: 15px;
  box-shadow: 4px 2px black;
  font-size: clamp(8px, 2vh, 18px);
  width: clamp(250px, 20vw, 350px);
}

.container {
  margin-bottom: 20px;
  margin-top: 10vh;
  width: 100vw;
}

.following-avatar {
  background-color: rgb(0, 0, 0);
  box-shadow: 4px 2px black;
  cursor: pointer;
}

.useravatar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  box-shadow: 4px 2px black;
  width: clamp(12px, 10vw, 200px);
  height: clamp(12px, 10vw, 200px);
  cursor: pointer;
}

.artist-name {
  word-break: break-word;
}

@media (min-width: 480px) {
  .Content {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
    width: 100vw;
  }

  .heading {
    margin-top: clamp(70px, 7vh, 150px);
    padding-left: min(30vw, 20px);
    align-self: flex-start;
    font-size: clamp(20px, 2vw, 30px);
    left: 0pt;
    z-index: 1;
    position: fixed;
  }

  .chartcont {
    width: 100%;
    height: 10vh;
  }

  .divider {
    color: black;
    width: 10vw;
  }

  .beats-container {
    width: 100vw;
    min-height: 215px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .following-avatar {
    width: clamp(20px, 10vw, 300px);
    height: clamp(20px, 10vw, 300px);
  }

  .artist-name {
    max-width: 200px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .Content {
    display: flex;
    width: 100vw;
    margin-left: 19vw;
  }

  .heading {
    padding-left: min(30vw, 20px);
    align-self: flex-start;
    font-size: clamp(20px, 2vw, 30px);
  }

  .chartcont {
    width: 100%;
    height: 10vh;
  }

  .container {
    width: 100%;
    margin-top: 0;
  }

  .artist-name {
    max-width: 90px;
    font-size: 3vw;
  }

  .divider {
    color: black;
    width: 10vw;
    margin-bottom: 200px;
  }

  .beats-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .following-avatar {
    width: clamp(12px, 20vw, 200px);
    height: clamp(12px, 20vw, 200px);
  }
}
