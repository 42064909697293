input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  margin-top: -20px;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-runnable-track {
  background: var(--primary);
  height: 0.3rem;
  border-radius: 4px;
}

/******** Firefox ********/
input[type='range']::-moz-range-track {
  background: var(--primary);
  height: 0.3rem;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -6px; /* Centers thumb on the track */
  background-color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
}

.playbackbtn-bar {
  background: transparent;
  border: none !important;
  color: white;
  margin-right: 2rem;
  margin-left: 30%;
  margin-right: 2vh;
  font-size: 2.5rem;
}

.vol-icon {
  background: transparent;
  border: none !important;
  margin-left: 2vw;
  padding-top: 0.5vh;
  font-size: 2.5rem;
}

.seek-bar {
  accent-color: white;
  width: clamp(200px, 40vw, 750px);
  min-width: 200px;
  bottom: 5vh;
  height: 100%;
  padding-top: 2vh;
  align-self: center;
}

@media (min-width: 1024px) {
  .playbackbutton {
    position: fixed;
    background: transparent;
    border: none !important;
    top: 85vh;
    left: 5vw;
    margin-left: 2rem;
    font-size: 2rem;
    z-index: 1;
  }
}

@media (max-width: 1024px) {
  .playbackbutton {
    position: fixed;
    text-align: center;
    font-size: 2rem;
    margin-left: 2rem;
    bottom: 12vh;
    left: 1vw;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: white;
    background-color: #000;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden !important;
    box-shadow: none !important;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .playbackbutton:hover {
    background-color: #333;
  }

  .playbackbutton:disabled {
    background-color: black !important;
  }

  .playbackbtn-bar:disabled {
    background-color: black !important;
    border: none;
    margin: auto;
  }

  .playbackbutton:active {
    transform: scale(0.9);
    background-color: #555;
  }
}
