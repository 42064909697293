.content {
  display: flex;
  flex-direction: column;
}

.headie {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15vh;
  margin-bottom: 10vh;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15vw;
  margin-left: 43%;
}
