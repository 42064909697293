.container {
  width: min(100vw, 2500px);
  display: flex;
  position: absolute;
  flex-direction: row;
  padding-left: 5vw;
  padding-top: min(800px, 8vh);
  text-align: start;
  justify-content: space-between;
  overflow-x: hidden;
}

.beat-cont {
  height: 100%;
  width: 50%;
  text-align: center;
  justify-content: center;
}

.beat-info-row {
  margin: 5rem 5rem;
  width: 77%;
}

.beat-info-space {
  align-content: flex-start;
}

.beat-title {
  margin-top: 3vh;
  margin-bottom: 0;
  z-index: 1;
  max-height: 5vh;
  font-size: 2.5vh;
  width: min(33vw, 750px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beat-artist {
  font-size: clamp(16px, 1.25vw, 34px);
  font-weight: lighter;
  text-align: center;
  color: black;
}

.stats-row {
  justify-content: space-between;
  text-align: center;
  align-items: center;
  align-self: center;
  width: clamp(325px, 25vw, 375px);
  margin: 2vh 0;
  height: 5vh;
}

.artwork {
  margin-top: 7vh;
  width: 100%;
  align-items: center;
  box-shadow: 12px 12px black;
  background: var(--primary) !important;
}

.artwork:hover {
  animation: none !important;
}

.suggested-container {
  width: min(37vw, 1000px);
  right: 0vw;
  height: 80vh;
  position: relative;
  text-align: start;
  align-items: start;
  justify-content: start;
  overflow-y: scroll;
  overflow-x: hidden;
}

.suggested-container > * {
  padding: 1vh 0 !important;
  margin: 1vh 0 !important;
}

.purchase-btn {
  border: solid 1px black;
  border-radius: 8px;
  height: clamp(20px, 5vh, 36px);
  box-shadow: 4px 2px black;
  font-size: clamp(8px, 2vh, 18px);
  width: clamp(250px, 20vw, 350px);
}

.refresh-button-div {
  position: relative;
  text-align: center;
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
    width: 100vw;
    left: 0;
    margin-top: 0;
    padding: 0;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .stats-row {
    width: clamp(300px, 40vw, 400px);
    margin-top: 1vh;
    margin-bottom: 2vh;
    text-align: center;
    justify-content: space-around;
  }

  .suggested-container {
    width: 90vw;
    right: 0vw;
    height: 100%;
    position: relative;
    font-size: clamp(16px, 3vw, 36px);
    text-align: start;
    align-items: start;
    justify-content: start;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .beat-cont {
    width: 100vw;
    margin-top: 150px;
  }

  .beat-title {
    text-align: center;
    width: 80vw;
    height: 5vh;
    max-width: 100%;
    white-space: pre;
    text-overflow: ellipsis;
    font-size: clamp(16px, 3vh, 42px);
  }

  .beat-artist {
    font-size: clamp(16px, 2vh, 36px);
  }

  .refresh-button-div {
    position: relative;
    top: auto;
    right: auto;
  }
}
