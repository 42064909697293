@media (min-width: 481) {
  .modal {
    display: flex;
    align-items: center;
    width: 10rem;
  }

  .form {
    margin: 2rem 2rem;
    width: 35vw;
  }

  .stems-table {
    margin-left: 12vw;
  }
}

@media (max-width: 480) {
  .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95vw;
  }

  .form {
    margin: 3vw;
    width: 95vw;
    height: 90vh;
    align-items: center;
  }

  .txt-inputs {
    font-size: min(16px) !important;
  }

  .stems-table {
    margin-left: 12vw;
    width: 100%;
    overflow-y: scroll;
  }

  .upload-btns-col {
    margin-right: 2vw;
  }
}
