.beats-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 15vh;
  justify-content: center;
  align-items: center;
}

.beats-container > * {
  margin: 2vh 0;
  padding: 0 1vh;
}

/* tablet css */

@media (min-width: 480px) and (max-width: 1024px) {
  .beats-container {
    margin-top: 2vh;
  }
}

/* mobile css */

@media (max-width: 480px) {
  .beats-container {
    padding-left: 2vw;
    padding-bottom: 10vh;
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow-x: hidden;
  }

  .beats-container > * {
    margin: 15px 0;
  }

  .scroll-btn {
    position: fixed;
    margin-right: 2rem;
    bottom: 12vh;
    right: 1vw;
    width: 60px;
    text-align: center;
    height: 60px;
    border-radius: 50%;
    color: white;
    background-color: #000;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow: hidden !important;
    box-shadow: none !important;
    z-index: 1;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .scroll-btn:hover {
    background-color: #333;
  }

  .scroll-btn:active {
    transform: scale(0.9);
    background-color: #555;
  }
}
