@media (min-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    width: 100vw;
  }

  .FAQ {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-style: italic;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7.5vh;
    font-weight: bold;
    font-size: clamp(16px, 2vw, 32px);
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1vw;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-top: 5vh;
    width: 10vw;
    height: auto;
  }
}

@media (max-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .headie {
    margin-top: 4vh;
    font-size: 6vw;
  }

  .FAQ {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-style: italic;
  }

  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7.5vh;
    font-weight: bold;
    font-size: 4vw;
    font-style: italic;
  }

  .link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    padding-bottom: 2.5vh;
    font-size: 4vw;
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    margin-top: 5vh;
    width: 25vw;
    height: auto;
  }
}
