.siteFooter {
  color: #fff;
  background-color: black;
  padding: 2.5vh 0;
  text-align: center;
  position: sticky;
  top: 100vh;
  width: 100vw;
  margin-top: 2px;
}

.container {
  max-width: 60vw;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.siteFooter p {
  margin: 0;
  font-size: clamp(10px, 0.9vw, 14px);
}

.siteFooter ul {
  font-size: clamp(10px, 0.8vw, 12px);
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.siteFooter ul li {
  margin: 0 0.7vw;
}

.siteFooter a {
  color: #fff;
  text-decoration: none;
}
