.row {
  width: 100vw;
  height: 110vh;
  align-items: center;
  padding-left: 5.5vw;
}

.header1 {
  font-size: 3.5vw;
  max-width: 40vw;
  padding-top: 5vw;
}

h5,
a {
  font-size: clamp(12px, 2vw, 24px);
}
/* Desktop CSS */

@media (min-width: 1024px) {
  .row {
    width: 100vw;
    height: 80vh;
    justify-content: center;
    padding-left: 5.5vw;
  }

  .header1 {
    font-size: 3.5vw;
    max-width: 40vw;
  }

  .paragraph1 {
    margin: 1rem 0rem 1rem 0rem;
    font-size: clamp(24px, 2.5vw, 48px);
    max-width: 40vw;
  }

  .moneygraph {
    width: min(30vw, 600px);
    height: auto;
    padding-top: min(16vh, 300px);
  }
}

/* Tablet CSS */

@media (min-width: 480px) and (max-width: 1024px) {
  .row {
    height: 50vh;
  }

  .moneygraph {
    margin: 64px 0 0 42px;
    width: 30vw;
    height: auto;
  }
}

/* Mobile CSS */

@media (max-width: 480px) {
  .row {
    padding-top: 5vh;
    width: 100%;
    height: 50vh;
    justify-content: center;
  }

  .moneygraph {
    width: 40vw;
    height: auto;
    padding-top: 4vh;
  }

  .header1 {
    font-size: 6.5vw;
    max-width: 50vw;
    padding-top: 3.7vh;
  }

  .paragraph1 {
    font-size: 4vw;
    max-width: 90%;
  }
}
