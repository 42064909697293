@import url('https://fonts.cdnfonts.com/css/silkscreen-2');

.container {
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ff0000;
}

.grapes {
  width: 15vw;
  height: auto;
}

.fourhundredfour {
  margin: 0;
  font-size: 5vw;
}

.thetext {
  font-family: 'Silkscreen', sans-serif;
  font-weight: bold;
  text-align: center;
}

/* Mobile CSS */

@media (max-width: 480px) {
  @import url('https://fonts.cdnfonts.com/css/silkscreen-2');

  header {
    display: none;
  }

  .container {
    margin-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ff0000;
  }

  .grapes {
    width: 25vw;
    height: auto;
    margin-bottom: 5vh;
  }

  .fourhundredfour {
    margin-bottom: 5vh;
    font-size: 10vw;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .thetext {
    font-family: 'Silkscreen', sans-serif;
    font-weight: bold;
    text-align: center;
    max-width: 85%;
  }
}
