/* this should probably be global */
.title-cont {
  position: absolute;
  top: 350px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.title {
  font-family: 'Norwester', sans-serif;
  z-index: 1;
  font-size: clamp(36px, 10vw, 64px);
  position: absolute;
  top: 10;
}

@media (min-width: 1921px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vh;
    text-align: center;
    height: 100vh;
  }

  .form {
    justify-items: center;
    width: 100%;
    margin-top: 1vh;
    height: 50vh;
  }

  .input {
    width: 450px;
    height: 50px;
    border-radius: 40px;
  }

  .logButton {
    font-size: 1vw;
    background: black;
    border-color: black;
    width: 17vw;
    max-width: 200px;
    height: 8vh;
    max-height: 75px;
    margin-bottom: 1vh;
    size: large;
  }

  h1 {
    margin-top: 1vh;
    font-size: 2vw;
  }
}

@media (min-width: 1024px) and (max-width: 1920px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    text-align: center;
    height: 100vh;
  }

  .form {
    justify-items: center;
    width: 50vw;
    height: 50vh;
  }

  .input {
    width: 27vw;
    max-width: 600px;
    height: 5vh;
    max-height: 50px;
    border-radius: 40px;
  }

  .logo {
    width: clamp(200px, 15vw, 400px);
    height: auto;
  }

  .logButton {
    font-size: clamp(16px, 1.5vw, 24px);
    background: black;
    border-color: black;
    width: 17vw;
    max-width: 180px;
    height: 8vh;
    max-height: 50px;
    margin-bottom: 1vh;
    size: large;
  }

  h1 {
    margin-top: 1vh;
    font-size: 2vw;
  }
}

/* @media screen and (min-width: 1200px) {
  .login-button {
    font-size: 2px;
  }
} */

/* Tablet CSS */

@media (min-width: 480px) and (max-width: 1024px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vh;
    text-align: center;
    height: 87.5vh;
    padding: 0px;
  }

  .logo {
    width: clamp(200px, 60vw, 400px);
    height: auto;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-top: 4vh;
    height: 50vh;
  }

  .loginText {
    font-size: 28px;
  }

  .input {
    width: 75vw;
    max-width: 600px;
    height: 5vh;
    max-height: 50px;
    border-radius: 40px;
  }

  .forgotPassword {
    width: 100%;
  }

  .logButton {
    font-size: 20px;
    background: black;
    border-color: black;
    width: 150px;
    max-width: 200px;
    height: 48px;
    max-height: 75px;
    margin-bottom: 1vh;
  }

  h1 {
    margin-top: 1vh;
    font-size: 4vw;
  }
}

/* Mobile CSS */

@media (max-width: 480px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6vh;
    text-align: center;
    height: 87.5vh;
    padding: 0px;
  }

  .logo {
    width: clamp(200px, 75vw, 350px);
    height: auto;
    margin-left: 20px;
  }

  .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-top: 4vh;
    height: 50vh;
  }

  .loginText {
    font-size: 22px;
  }

  .input {
    width: 75vw;
    max-width: 600px;
    height: 5vh;
    max-height: 50px;
    border-radius: 40px;
  }

  .logButton {
    font-size: 4vw;
    background: black;
    border-color: black;
    width: 150px;
    max-width: 200px;
    height: 48px;
    max-height: 75px;
    margin-bottom: 1vh;
  }
}
