.algo-btn {
  border: solid 1px black;
  border-radius: 8px;
  height: 36px;
  margin: 10px;
  position: absolute;
  margin-left: 3vw;
  top: max(10vh);
  box-shadow: 5px 5px rgb(232, 162, 21);
  background-color: black;
  color: rgb(240, 240, 240);
  font-size: 18px;
  position: fixed;
  top: clamp(100px, 13vh, 400px);
}
