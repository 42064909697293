@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary: #ffbd59;
  --secondary: #38639a;
  --dark-text: #191919;
}

* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

::-webkit-slider-thumb {
  background: transparent;
}

html {
  margin: 0 !important;
}

.WebContainer {
  width: 100%;
  height: auto;
}

.App {
  background-color: var(--primary);
}

body {
  background-color: var(--primary) !important;
  color: var(--dark-text) !important;
  margin: 0;
}
/* ANTD overrides */
.ant-layout-content,
.ant-layout {
  background-color: var(--primary) !important;
}

.ant-spin {
  color: black;
}

.ant-spin-dot-item {
  background-color: black !important;
}

.ant-dropdown-menu {
  background-color: black !important;
  max-height: 350px;
  overflow: auto;
}

.ant-dropdown-menu-item {
  color: white !important;
}

.ant-menu-item-selected {
  background-color: var(--primary) !important;
}

.ant-dropdown-menu-submenu {
  border-radius: 8px;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: #ffbd59;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-selected:hover {
  background-color: #323232 !important;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  background-color: #3d3d3d !important;
}

.ant-notification-topRight {
  margin-top: 5vh;
}

.button-lg-round {
  width: '200px';
  height: '65px';
  font-weight: 'bold';
}
.input-round {
  border-radius: '15px';
  height: '50px';
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Roboto Condensed', sans-serif;
  font-style: italic;
}

p {
  font-family: 'Roboto Condensed', sans-serif;
}

@media (max-width: 480px) {
  .ant-calendar-range {
    width: 320px;
  }
  .ant-calendar-range-part {
    width: 100%;
  }
}
