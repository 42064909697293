.beats-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 480px) and (max-width: 1024px) {
  .beats-container {
    margin-top: 2vh;
  }
}

@media (max-width: 480px) {
  .beats-container {
    margin-top: 2vh;
  }
}
