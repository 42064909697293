.box {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: #fff;
  padding: 0 1vw;
  height: 750px;
}
@media (max-width: 991px) {
  .box {
    margin: 30px 15px;
  }
}
.div {
  border-radius: 20px;
  background-color: #191919;
  display: flex;
  padding-bottom: 30px;
  flex-direction: column;
}
.div-2 {
  border-radius: 20px 20px 0px 0px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 41px;
}
@media (max-width: 991px) {
  .div-2 {
    padding: 0 20px;
  }
}
.div-3 {
  border-radius: 1000px;
  background-color: #6cbd46;
  z-index: 10;
  margin-top: -15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 4px;
  justify-content: center;
  padding: 13px 24px;
  font: 600 14px/114% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-4 {
  text-align: center;
  text-transform: uppercase;
  margin-top: 32px;
  font: 600 28px/100% Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-5 {
  text-align: center;
  align-self: center;
  max-width: 400px;
  margin-top: 16px;
  font: 500 16px/26px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-6 {
  display: flex;
  gap: 12px;
  white-space: nowrap;
}
@media (max-width: 991px) {
  .div-6 {
    white-space: initial;
  }
}
.div-7 {
  font: 700 48px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .div-7 {
    font-size: 40px;
  }
}
.div-8 {
  margin: auto 0;
  font: 500 16px/162.5% Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}
.div-9 {
  display: flex;
  height: 330px;
  margin-top: 40px;
  flex-direction: column;
  align-items: start;
  font-size: 16px;
  font-weight: 400;
  line-height: 162.5%;
  padding: 0 30px;
}
@media (max-width: 991px) {
  .div-9 {
    padding: 0 20px;
  }
}
.div-10 {
  display: flex;
  gap: 12px;
}
.img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  margin: auto 0;
}
.div-11 {
  font-family: Poppins, sans-serif;
}
.div-12 {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.div-13 {
  font-family: Poppins, sans-serif;
}
.div-14 {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.div-15 {
  font-family: Poppins, sans-serif;
}
.div-16 {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.div-17 {
  font-family: Poppins, sans-serif;
}
.div-18 {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.div-19 {
  font-family: Poppins, sans-serif;
}
.div-20 {
  display: flex;
  margin-top: 12px;
  gap: 12px;
}
.div-21 {
  font-family: Poppins, sans-serif;
}
.div-22 {
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  box-shadow: 0px 0px 100px 0px rgba(25, 25, 25, 0.15);
  border-color: rgba(108, 189, 70, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #6bbb45;
  align-self: stretch;
  display: flex;
  margin-top: 45px;
  width: 100%;
  color: var(--White, #fff);
  font-weight: 500;
  text-align: center;
  line-height: 200%;
  padding: 20px 60px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .div-22 {
    margin-top: 40px;
    padding: 0 20px;
  }
}
.div-23 {
  display: flex;
  gap: 17px;
}
.div-24 {
  font-family: Poppins, sans-serif;
  margin: auto 0;
}
.img-2 {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
}
