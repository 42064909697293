@media (min-width: 3001px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .container {
    margin-top: 5vh;
  }

  .profile-info-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 5rem;
    margin-right: 10rem;
    width: 77%;
  }

  .profile-info-space {
    align-content: flex-start;
  }

  .text-col {
    margin: 0rem 5rem;
    text-align: start;
  }

  .useravatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 4px 2px black;
    width: clamp(20px, 15vw, 300px);
    height: clamp(20px, 15vw, 300px);
    cursor: pointer;
    transition: 0.5s ease;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .container:hover .useravatar {
    opacity: 0.4;
  }

  .container:hover .middle {
    opacity: 0.4;
  }

  .texto {
    margin-left: 6.5vw;
    margin-top: -19vh;
    color: grey;
    font-size: 3vw;
  }

  .username {
    font-size: clamp(20px, 3vw, 100px);
    margin: 0 1vw;
  }

  .bio {
    font-size: clamp(15px, 1vw, 50px);
    margin: 1vw;
    max-width: 60%;
  }
}

@media (min-width: 2201px) and (max-width: 3000px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;
  }

  .container {
    margin-top: 5vh;
  }

  .profile-info-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5rem 5rem;
    width: 77%;
  }

  .profile-info-space {
    align-content: flex-start;
  }

  .text-col {
    margin: 0rem 5rem;
    text-align: start;
  }

  .useravatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 4px 2px black;
    width: clamp(20px, 15vw, 300px);
    height: clamp(20px, 15vw, 300px);
    cursor: pointer;
    transition: 0.5s ease;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .container:hover .useravatar {
    opacity: 0.4;
  }

  .container:hover .middle {
    opacity: 0.4;
  }

  .texto {
    margin-left: 6.5vw;
    margin-top: -19vh;
    color: grey;
    font-size: 3vw;
  }

  .username {
    font-size: clamp(20px, 3vw, 100px);
    margin: 0 1vw;
  }

  .bio {
    font-size: clamp(15px, 1vw, 50px);
    margin: 1vw;
    max-width: 60%;
  }
}

@media (min-width: 1024px) and (max-width: 2200px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100%;
    padding: 10px;
  }

  .beats {
    margin-right: '22vw';
  }

  .container {
    margin-top: 5vh;
  }

  .profile-info-row {
    margin: 5rem 5rem;
    width: 77%;
  }

  .profile-info-space {
    align-content: flex-start;
  }

  .text-col {
    margin: 0rem 5rem;
    text-align: start;
  }

  .useravatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 4px 2px black;
    width: clamp(20px, 15vw, 300px);
    height: clamp(20px, 15vw, 300px);
    cursor: pointer;
    transition: 0.5s ease;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .container:hover .useravatar {
    opacity: 0.4;
  }

  .container:hover .middle {
    opacity: 0.4;
  }

  .texto {
    margin-left: 6.5vw;
    margin-top: -19vh;
    color: grey;
    font-size: 3vw;
  }

  .username {
    font-size: clamp(20px, 3vw, 100px);
    margin: 0 1vw;
  }

  .bio {
    font-size: clamp(15px, 1vw, 50px);
    margin: 1vw;
    max-width: 60%;
  }
}

@media (min-width: 480px) and (max-width: 1024px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100%;
    padding: 10px;
  }

  .container {
    margin-top: 5vh;
  }

  .profile-info-row {
    margin: 5rem 5rem;
    width: 77%;
  }

  .profile-info-space {
    display: flex;
    flex-direction: column;
    padding-left: 7rem;
  }

  .text-col {
    margin: 0rem 5rem;
    text-align: start;
  }

  .useravatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(0, 0, 0);
    box-shadow: 4px 2px black;
    width: clamp(20px, 15vw, 300px);
    height: clamp(20px, 15vw, 300px);
    cursor: pointer;
    transition: 0.5s ease;
  }

  .middle {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    text-align: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }

  .container:hover .useravatar {
    opacity: 0.4;
  }

  .container:hover .middle {
    opacity: 0.4;
  }

  .texto {
    margin-left: 6.5vw;
    margin-top: -19vh;
    color: grey;
    font-size: 3vw;
  }

  .username {
    font-size: clamp(20px, 3vw, 100px);
    margin: 0 1vw;
  }

  .followers {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
  }

  .bio {
    font-size: clamp(15px, 1vw, 50px);
    margin: 1vw;
    max-width: 60%;
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 0;
    width: 85vw;
    justify-content: center;
    align-items: center;
    padding: 1vh;
  }

  .profile-info-row {
    flex-direction: column;
    justify-content: center;
    margin: 1rem 0rem;
    align-content: center;
    text-align: center;
  }

  .text-col {
    margin: 5px;
  }

  .useravatar {
    margin-bottom: 2vh;
    margin-left: auto;
    margin-right: auto;
  }

  .profile-info-space profile-info-row {
    align-content: center;
  }
}
