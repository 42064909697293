.masterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 1vw;
}

.lastUpdate {
  font-style: italic;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
}

.section {
  text-decoration: underline;
}

.subSection {
}
