/* global */
/* global */
.row {
  background-color: black;
  color: white;
  width: 100%;
  align-items: center;
  padding-left: 5.5vw;
}

.header1 {
  color: #ffbd59;
  font-size: 3.5vw;
  max-width: 40vw;
  padding-top: 10vw;
}

/* Desktop CSS */

@media (min-width: 1024px) {
  .row {
    background-color: black;
    color: white;
    width: 100vw;
    height: 80vh;
    justify-content: center;
    padding-left: 5.5vw;
  }

  .header1 {
    color: #ffbd59;
    font-size: 3.5vw;
    max-width: 40vw;
    padding-top: 9vw;
  }

  .paragraph1 {
    color: #ffbd59;
    margin: 1rem 0rem 1rem 0rem;
    font-size: clamp(24px, 2.5vw, 48px);
    max-width: 40vw;
  }

  .moneyhands {
    width: min(30vw, 600px);
    height: auto;
    padding-top: min(16vh, 300px);
  }
}

/* Tablet CSS */

@media (min-width: 480px) and (max-width: 1024px) {
  .row {
    padding-top: 5vh;
    width: 100%;
    height: 50vh;
    justify-content: center;
  }

  .moneyhands {
    width: 30vw;
    height: auto;
    margin: 64px 0 0 42px;
  }
}
/* Mobile CSS */

@media (max-width: 480px) {
  .row {
    padding-top: 5vh;
    width: 100%;
    height: 50vh;
    justify-content: center;
  }

  .header1 {
    color: #ffbd59;
    font-size: 6.5vw;
    max-width: 50vw;
    padding-top: 7.5vh;
  }

  .moneyhands {
    width: 40vw;
    height: auto;
    padding-top: 4vh;
  }

  .paragraph1 {
    font-size: clamp(16px, 1vw, 48px);
    max-width: 90%;
  }
}
