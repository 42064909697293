.layout {
  width: 100vw;
  height: 100vh;
  min-height: 100%;
}

.content {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

@media (max-width: 480px) {
  .content {
    margin-bottom: 10vh;
    width: 100vw;
  }
}
