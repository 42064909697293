.container {
  position: fixed;
  height: 10vh;
  width: 100vw;
  background-color: black;
  bottom: 0;
}

.btn {
  height: 100%;
  color: white;
  background: black;
  overflow: visible;
  font-size: 24px;
}

.icon {
  font-size: 16px;
}

.ant-modal-body {
  opacity: 0% !important;
}

.search-bar-hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s linear;
}

.search-bar-visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.25s linear;
}
