.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

body {
  height: auto !important;
  padding: 0 !important;
}

@media (min-width: 1024px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 250px;
    height: auto;
    margin-top: 4vh;
    margin-bottom: 2.5vh;
  }

  .headie {
    font-size: 2vw;
    font-weight: normal;
    margin: 5vh;
  }

  .subTiers {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100vw;
    flex-wrap: nowrap;
  }

  .Text {
    font-size: 1.5vw;
    margin-top: 5vh;
    color: white;
  }

  .subText {
    font-size: 1vw;
    color: white;
    margin-top: 0px;
    margin-bottom: 3vh;
    font-weight: normal;
  }

  .subText2 {
    font-size: 1vw;
    color: white;
    margin-top: 0px;
    margin-bottom: 17vh;
    font-weight: normal;
    font-style: italic;
  }

  .subText3 {
    font-size: 1vw;
    color: white;
    margin-top: 0px;
    margin-bottom: 20vh;
    font-weight: normal;
  }

  .subRows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: #333333;
    border-radius: 16px;
    background-color: #333333;
    width: 16vw;
    margin-left: 5vw;
    margin-right: 5vw;
    box-shadow: 6px 3px black;
  }

  .buttons {
    font-size: 1.25vw;
    font-weight: bold;
    font-style: italic;
    width: 12vw;
    height: 8vh;
    max-height: 75px;
    border-radius: 12px;
    background-color: black;
    border: none;
    color: white;
    margin-bottom: 5vh;
  }

  .skip {
    font-size: 1vw;
    margin-top: 2vh;
  }
}

@media (max-width: 1023px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: 15vw;
    height: auto;
    margin-top: 2vh;
    margin-bottom: 2.5vh;
  }

  .headie {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 7vw;
    font-weight: normal;
    margin: 105px 0 25px 0;
  }

  .subTiers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .Text {
    font-size: 5vw;
    margin-top: 2vh;
    color: white;
  }

  .subText {
    color: white;
    margin-top: 0px;
    font-weight: normal;
    margin-bottom: 2vh;
  }

  .subText2 {
    color: white;
    margin-top: 0px;
    margin-bottom: 2vh;
    font-weight: normal;
    font-style: italic;
  }

  .subText3 {
    color: white;
    margin-top: 0px;
    margin-bottom: 2vh;
    font-weight: normal;
  }

  .subRows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-style: solid;
    border-color: #333333;
    border-radius: 16px;
    background-color: #333333;
    width: 75%;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vh;
    box-shadow: 6px 3px black;
  }

  .buttons {
    font-size: 3vw;
    font-weight: bold;
    font-style: italic;
    width: 36vw;
    max-width: 200px;
    height: 5vh;
    max-height: 75px;
    border-radius: 12px;
    background-color: black;
    border: none;
    color: white;
    margin-bottom: 2vh;
  }

  .skip {
    margin-top: 1vh;
    margin-bottom: 5vh;
  }
}
